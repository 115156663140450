.itemMenu {
  background: white;
  width: 100%;
  height: 40px;
  margin: 0px;
  padding: 0px;
  font-weight: 500;
  font-size: 1.2rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-left: 32px;
  align-items: center;
  cursor: pointer;
  gap: 8px;
}
.itemMenu:hover {
  filter: brightness(95%);
}
