.banner-password{
    background: #FFF5D4 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    width: 100%;
    margin-top: 4vh;
    padding: 12px;
    display: none;
}

.banner-password h5{
    text-align: left;
    letter-spacing: 0px;
    color: #8E0A0A;
    opacity: 1;
}

ul.help-password{
    list-style-type: disc;
    text-align: left;
    letter-spacing: 0px;
    color: #313131;
    opacity: 1;
}
.fa-info-circle:before {
    vertical-align: sub;
    font-size:"15px";
    color:'#32C0FB';
}
.fa-info-circle {
    font-size:15px;
    color:#32C0FB;
    margin-left: 1vh;
    height:20px;
}