.dashboard__title {
  height: 100px;
}

.row-atualizar {
  display: flex !important;
  justify-content: space-between !important;
}

.dashboard__items {
  margin-top: 16px;
  width: 95%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 8px;
}

.badge__dashboard {
  width: 10rem;
  height: 4.2rem;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  cursor: pointer;
}

.badge__dashboard span {
  font-size: 28px;
}
