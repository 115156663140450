.table-container {
  /* max-height: 340px; */
  overflow-y: auto;
  border-collapse: collapse;
  padding-top: 0px;
}
.table-container thead {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
}

.linhaTabela > td {
  padding: 2px !important;
}

.linhaTabela > td > div {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
