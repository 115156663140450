.table-container {
  /* max-height: 340px; */
  overflow-y: auto;
  border-collapse: collapse;
  padding: 0px 6px 6px;
}
.table-container thead {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
}

.linhaTabela > td {
  padding: 2px !important;
}

.table-container > table {
  margin: 0px;
}
