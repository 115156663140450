.menu-left {
  background: #085ed6 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  opacity: 1;
  text-align: left;
  padding: 20px 20px;
  min-width: 220px;
  width: 220px;
}
.menu-left a {
  text-decoration: none;
  letter-spacing: 0px;
  opacity: 1;
}

.menu-left a:hover {
  color: blue;
}

.user-name {
  margin: 0px;
  color: white;
  font-weight: 600;
}
.user-name-container {
  color: white;
  margin-bottom: 16px;
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
